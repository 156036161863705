import React, { Component } from 'react'
import Loader from 'react-loaders'
import './App.css'
import 'loaders.css/loaders.min.css'
class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            Taher Corp
          </p>
          <Loader type="ball-grid-beat" active />
        </header>
      </div>
    )
  }
}

export default App
